/**
 * Decode all anchor elements with the class names of 'email' or 'tel'
 */
export function unobsfuscateAll() {
    for (var e = document.getElementsByTagName && document.getElementsByTagName('a'), t = 0; e && t < e.length; t++) {
        if (e[t].className.indexOf('email') >= 0) {
            var linkElement = e[t];
            var email = decode(linkElement.textContent);
            linkElement.href = 'mailto:' + email;
            linkElement.textContent = email;
        }
        else if (e[t].className.indexOf("tel") >= 0) {
            var linkElement = e[t];
            var tel = decode(linkElement.textContent);
            linkElement.href = 'tel:' + tel;
            linkElement.textContent = tel;
        }
        else
            continue;
    }
}
function decode(encodedString) {
    if (!encodedString)
        return '';
    var plainString = '';
    var keyInHex = encodedString.substring(0, 2);
    var key = parseInt(keyInHex, 16);
    for (var n = 2; n < encodedString.length; n += 2) {
        var charInHex = encodedString.substring(n, n + 2);
        var char = parseInt(charInHex, 16);
        var output = char ^ key & 0xff;
        plainString += String.fromCharCode(output);
    }
    return plainString;
}
export function encode(plainString, key) {
    var encodedKey = (key & 0xff).toString(16);
    var encodedString = make2DigitsLong(encodedKey);
    for (var n = 0; n < plainString.length; n++) {
        var charCode = plainString.charCodeAt(n);
        var encoded = charCode ^ key & 0xff;
        var value = encoded.toString(16);
        encodedString += make2DigitsLong(value);
    }
    return encodedString;
}
/**
 * A single character is left zero padded to make the string length 2
 * @param value
 * @returns
 */
function make2DigitsLong(value) {
    return value.length === 1 ? '0' + value : '' + value.substring(0, 2);
}
